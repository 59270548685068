<template>
  <div class="page-templates">
    <h2 class="p-title">公众号模板</h2>
    <yos-tab-nav :list="tabNav" />
    <div class="p-card">
      <!-- <ul class="list">
        <ol>选择公众号</ol>
        <li
          v-for="item in authorizers"
          :key="item.authorizerAppid"
          :class="item.authorizerAppid === $route.query.appid ? 'active' : '' "
          @click="onTabChange(item.authorizerAppid)"
        >
          {{ item.nickName }}
        </li>
      </ul> -->
      <!-- <div class="cell">
      </div> -->
      <!-- <yos-button class="yos-button-primary" to="/fun/template_libraries/new">批量添加</yos-button> -->
      <div class="p-search-bar">
        <span>公众号：</span>
        <select v-model="appid" class="yos-select" @change="onTabChange">
          <option
            v-for="item in authorizers"
            :key="item.authorizerAppid"
            :value="item.authorizerAppid"
          >
          {{ item.nickName }}
          </option>
        </select>
        <yos-button class="yos-button-primary" :to="`/fun/template_libraries?appid=${appid}`">从模版库中添加</yos-button>
        <span>还可添加<strong class="primary-text">{{ 25 - dataList.length }}</strong>个模版</span>
      </div>
      <table cellspacing="0" class="m-table">
        <thead>
          <tr>
            <th>序号</th>
            <th>模版库编号</th>
            <th>模版ID</th>
            <th>标题</th>
            <th>一级行业</th>
            <th>二级行业</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in dataList" :key="item.templateId">
            <td>{{ idx + 1 }}</td>
            <td>{{ item.libraryId }}</td>
            <td>{{ item.templateId }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.primaryIndustry }}</td>
            <td>{{ item.deputyIndustry }}</td>
            <td>
              <span class="option" @click="onDetail(item)">详情</span>
              <span class="option" @click="onDelete(item.templateId)">删除</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import YosTabNav from '../../components/other/tab-nav'
import { reactive, toRefs } from 'vue'
import { useServer, usePage, useTabNav, useToast, useDialog } from '../../hook'
import { useRoute, useRouter } from 'vue-router'
import {
  getTemplates,
  deleteTemplate,
  getAuthorizers
} from '../../data'
import { serialize, getPageNavs } from '../../util'

export default {
  name: 'Message',
  components: {
    YosTabNav
  },
  setup() {
    const dialog = useDialog()
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const nav = getPageNavs('fun')
    const state = reactive({
      keyword: route.query.keyword || '',
      authorizer: route.query.authorizer || '',
      platform: parseInt(route.query.platform) || 1,
      dataList: [],
      authorizers: [],
      appid: route.query.appid || ''
    })

    const { page, baseUrl } = usePage('/fun/templates', { keyword: state.keyword })

    const setTemplatesParams = useServer(getTemplates, ({ dataList }) => {
      state.dataList = dataList
    })

    const setAuthorizersParams = useServer(getAuthorizers, ({ authorizers = [] }) => {
      const current = authorizers.find(item => item.authorizerAppid === state.appid)
      state.authorizers = authorizers

      if (current === undefined && authorizers.length > 0) {
        state.appid = authorizers[0].authorizerAppid
        router.replace(serialize(baseUrl, { appid: state.appid }))
      }

      setTemplatesParams({ authorizerAppid: (current || authorizers[0]).authorizerAppid })
    })

    const setDeleteTemplateParams = useServer(deleteTemplate, ({ msg }, { templateId }) => {
      toast(msg)
      state.dataList = state.dataList.filter(item => item.templateId !== templateId)
    })

    const onTabChange = (event) => {
      const authorizerAppid = event.target.value
      router.replace(serialize(baseUrl, { appid: authorizerAppid }))
      setTemplatesParams({ authorizerAppid })
    }

    const onDetail = (template) => {
      sessionStorage.setItem(template.templateId, JSON.stringify(template))
      router.push(`/fun/templates/${route.query.appid}/${template.templateId}`)
    }

    const onDelete = (templateId) => {
      dialog({
        title: '删除模板',
        content: '你确定要删除该模板？/n删除后，模版ID将失效且不可恢复，使用模版ID的程序也将失效',
        onOk: () => {
          setDeleteTemplateParams({ authorizerAppid: route.query.appid, templateId })
        }
      })
    }

    setAuthorizersParams({ status: 1, size: 1000 })

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      page,
      baseUrl,
      onTabChange,
      onDelete,
      onDetail
    }
  }
}
</script>

<style lang="postcss">
.page-templates {
  .primary-text {
    color: #1aad19;
  }

  .list {
    width: 200px;
    margin-right: 10px;
    border: 1px solid #e7e7eb;

    ol {
      line-height: 40px;
      background-color: #f6f8f9;
      color: #9a9a9a;
      padding: 0 10px;
    }

    li {
      border-top: 1px solid #e7e7eb;
      line-height: 40px;
      padding: 0 10px;
      cursor: pointer;

      &.active {
        color: #1aad19;
      }
    }
  }

  .m-table {
    margin-top: 0;
  }
}
</style>
